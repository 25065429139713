.stars-container {
	display: flex;
	justify-content: center;
	gap: 10px;
}

.star {
	font-size: 3rem;
	font-weight: bold;
	background: linear-gradient(180deg, #FFFFFF 0%, rgb(241, 90, 36) 50%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	display: inline-block;
}